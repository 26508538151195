.fetchButton
{
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 15px;
  font-size: 16px;
  box-shadow: 5px 5px 15px rgba(8, 0, 0, 0.917);
  border-radius: 5px;
}
.searchInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 25%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.limitInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 5%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.modelInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 15%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptDropdownInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: auto; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptInput
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  height:140px; /* Increase the height */
  box-shadow: 2px 4px 3px rgba(4, 4, 4, 0.8);
  margin-bottom: 8px;
}
.promptFullTextInput
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  height:400px; /* Increase the height */
  box-shadow: 2px 4px 3px rgba(4, 4, 4, 0.8);
  margin-bottom: 8px;
}
.promptTag
{
  width: 49%;
  padding: 2px;
  font-size: 16px;
  box-shadow: 2px 3px 2px rgba(4, 4, 4, 0.8);
  margin-bottom: 4px;
}
.outputFormat
{
  width: 99%;
  box-shadow: 2px 3px 2px rgba(4, 4, 4, 0.8);
  padding: 2px;
  font-size: 16px;
  box-shadow: 4px 4px 8px rgba(4, 4, 4, 0.8);
  margin-top: 12px;
  margin-bottom: 18px;
}
.outputDetailsFormat
{
  margin-top: 18px;
  margin-bottom: 24px;
  box-shadow: 2px 3px 8px rgba(4, 4, 4, 0.8);
}
.responseFormat
{
  border: 1px dotted black;
  padding: 2px;
  background-color: #e4ede8;
}
.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.sourceDocumentButton{
  background-color: rgb(196, 217, 244);
  border: none;
  color: #010000;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.generateButton
{
  background-color: rgb(137, 148, 172);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.8);
  padding: 6px 6px;
  font-size: 24px; /* make the button text extremely small */
}
.fastGenerateButton
{
  background-color: rgba(114, 77, 28, 0.825);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.8);
  padding: 3px 3px;
  font-size: 18px; /* make the button text extremely small */
}

.practiceButton
{
  background-color: rgb(10, 128, 81);
  border: 2px solid rgb(55, 6, 6);
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(169, 148, 148, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.audioButton
{
  background-color: rgb(170, 154, 5);
  border: 2px solid rgb(55, 6, 6);
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(26, 12, 12, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.imageButton
{
  background-color: rgb(180, 121, 239);
  border: 2px solid rgb(55, 6, 6);
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(37, 13, 13, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.imageButton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.searchButton
{
  background-color: rgb(48, 121, 176);
  border: 2px solid rgb(55, 6, 6);
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(37, 13, 13, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.newsButton {
  background-color: rgb(177, 148, 42);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.techNewsButton {
  background-color: rgb(17, 118, 159);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.youtubeButton {
  background-color: rgb(218, 193, 103);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.publicHomeWork {
  padding: 3px 3px;
  font-size: 18px;
  background-color: #4074b9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);;
}
.privateHomeWork {
  padding: 3px 3px;
  font-size: 18px;
  background-color: #14a78c;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);;
}
.musicButton {
  background-color: rgb(176, 205, 13);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}
.storiesButton {
  background-color: rgb(20, 195, 218);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 18px; /* make the button text extremely small */
}
.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 18px; /* make the button text extremely small */
}

.signoutbutton {
  background-color: rgb(237, 182, 15);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}

.signupbutton {
  background-color: rgb(124, 94, 1);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}

.signinbutton {
  background-color: rgb(45, 129, 3);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 16px; /* make the button text extremely small */
}

html, body {
  margin-left: 1px;
  margin-right: 1px;
  padding: 0;
  width: 99%;
  overflow-x: hidden;
}

#root {
  width: 99%;
  margin-left: 1px;
  margin-right: 1px;
}

/* src/App.css */

.faEditButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  font-size: 1.2em;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.popupContent label {
  display: block;
  margin-bottom: 10px;
}

.popupContent input,
.popupContent textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.buttonGroup button {
  padding: 2px 2px;
  cursor: pointer;
}
.flashing {
  animation: flash 1s infinite;
  font-weight: bold;
  background-color: #aad0e3;
  border-radius: 10px;
}

@keyframes flash {
  0% { color: rgb(92, 219, 156); }
  50% { color: rgb(66, 175, 68); }
  100% { color: rgb(0, 17, 5); }
}

form {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 5px; /* Move the text box up 10 pixels */
}
.inputtextbox
{
  padding: 5px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 55%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid black;
}
.addbutton {
  background-color:rgb(16, 130, 16);
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(1, 55, 20, 0.8);
  width: 10%; /* Increase the width */
  height: 48px; /* Increase the height */
}

.signoutbutton {
  background-color: orange;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 14px;
  padding: 2px 2px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 18px; /* make the button text extremely small */
}
.textbutton {
  background-color: grey;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 2px 2px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 18px; /* make the button text extremely small */
}

.markcompletebutton {
  background-color: rgb(246, 249, 249);
  border: black;
  border: 2px solid black;
  color: rgb(21, 124, 69);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}

.donemarkcompletebutton {
  background-color: rgb(6, 88, 28);
  border: black;
  border: 2px solid black;
  color: rgb(252, 254, 253);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}
.showcompletedbutton {
  background-color: rgb(239, 216, 14);
  border: none;
  color: black;
  cursor: pointer;
  margin-bottom: 110px;
  padding: 10px 10px;
  font-size: 16px; /* make the button text extremely small */
}
.textbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.signoutbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.textinput {
  padding: 2px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 65%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid #070707;
  box-shadow: 2px 2px 8px rgba(4, 4, 4, 0.8);
}

.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 18px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 18px; /* make the button text extremely small */
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 18px; /* make the button text extremely small */
}
.editForm {
  position: fixed;
  padding: 10px;
  width: 60%;
  bottom: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  background-color: whitesmoke;
  box-shadow: 2px 4px 12px rgb(7, 7, 7);
  margin-bottom: 5px; /* Move the text box up 10 pixels */
  font-size: 18px;
}

.radio-options {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  font-size: 15px;
}

.radio-label {
  padding: 2px 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.action_button {
  margin-left: 8px;
  margin-right: 8px;
  padding: 2px 2px;
  border: 1px solid black;
  background-color: #f8fafb;
  color: rgb(7, 27, 63);
  box-shadow: 4px 2px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  font-size: 17px;
  
}

.action_button:hover {
  box-shadow: 8px 8px 8px rgba(34, 33, 33, 0.4);
  transform: translateX(-1px);
  transform: scale(0.90);
  margin-right: 10px;
  border: 3px solid black;
}

.action_button_flashing {
  box-shadow: 8px 8px 8px rgba(34, 33, 33, 0.4);
  color: rgb(7, 27, 63);
  background-color: #aad0e3;
  border: 2px solid rgb(9, 0, 0);
  animation: actionflash 1s infinite;
  font-weight: bold;
  font-size: 17px;
  padding: 1px 1px;
  margin-left: 8px;
  margin-right: 8px;
}

@keyframes actionflash {
  0% { color: rgb(163, 187, 204); }
  50% { color: rgb(69, 108, 135); }
  100% { color: rgb(0, 4, 8); }
}
.action_button_selected {
  font-size: 16px;
  margin-left: 8px;
  border-radius: 10px;
  padding: 2px 2px;
  border: 2px solid black;
  background-color: rgb(172, 203, 179);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.button {
  margin-left: 8px;
  padding: 2px 2px;
  border: 1px solid black;
  background-color: #f1f3f6;
  color: rgb(3, 22, 57);
  box-shadow: 2px 2px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
}

.button:hover {
  box-shadow: 6px 6px 8px rgba(34, 33, 33, 0.4);
  transform: translateX(-1px);
  border: 2px solid black;
}

.button_selected {
  border: 3px solid black;
  background-color: rgb(196, 228, 204);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  font-size: 16px;
  border-radius: 10px;
}

.llm_button {
  padding: 1px 1px;
  border: 3px solid black;
  background-color: rgb(246, 250, 247);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  font-size: 16px;
  border-radius: 10px;
  margin-left: 8px;
}

.llm_button_selected {
  padding: 1px 1px;
  border: 3px solid black;
  background-color: rgb(201, 230, 208);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  font-size: 16px;
  border-radius: 10px;
  margin-left: 8px;
}

.button-section {
  border: 2px dotted #ccc;
  padding: 10px;
  margin: 10px 0;
  position: relative;
}

.button-section::before {
  content: attr(data-title);
  position: absolute;
  top: -10px;
  left: 10px;
  background: white;
  padding: 0 5px;
  font-size: 14px;
  color: #666;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 4px 2px 10px rgba(91, 82, 82, 0.806);
  z-index: 1001;
  width: 92%;
  pointer-events: all;
}

.main-content {
  transition: filter 0.3s;
}

.main-content.dimmed {
  opacity: 0.9;
  pointer-events: none;
}

.reaction-buttons {
  display: flex;
  gap: 8px;
  margin: 8px 0;
}

.reaction-btn {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 2px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: rgb(237, 233, 233);
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 4px 2px 4px rgba(91, 82, 82, 0.806);
}

.reaction-btn:hover {
  background: #f5f5f5;
  transform: translateY(-1px);
}

.reaction-btn.active {
  background: #9ddfe2;
  border-color: #2196f3;
  color: #010a11;
  font-weight: bold;
}

